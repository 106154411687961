import React from 'react';
import { graphql } from 'gatsby';
import TimeLineLayout from './components/TimeLineLayout';
import TimeLineBar from '../../components/TimelineBarChart';

const TimeLineEventAndPeople = ({ data, pageContext }) => {
  const { allEventLists, allPeopleLists } = data;
  const {
    seasonTitle,
    seasonEndDate,
    seasonBeginDate,
    timelineJumpDate,
    seasonBeginDateAdBc,
    seasonEndDateAdBc,
    timelineJumpToDateAdBc,
  } = pageContext;
  const startDate = seasonBeginDateAdBc === 'BC' ? seasonBeginDate.split('-')[1] : seasonBeginDate.split('-')[0];
  const endDate = seasonEndDateAdBc === 'BC' ? seasonEndDate.split('-')[1] : seasonEndDate.split('-')[0];

  const checkIfTimePeriodIsLessThan20 = (startDate, endDate) => endDate - startDate < 20;

  const timeLineData = [...allEventLists.nodes, ...allPeopleLists.nodes]
    .map((item) => {
      return {
        startTime: item.data.eventYear,
        title: Array.isArray(item?.data?.title) ? item?.data?.title[0].text : item?.data?.title.text,
        image: item?.data?.timelineProfilePhoto?.fixed?.src || item?.data?.image?.url || '',
        type: item.type,
        uid: item.uid,
        startYear:
          item.data.yearADBC === 'BC' ? `-${item.data.eventYear.split('-')[0]}` : item.data.eventYear.split('-')[0],
        endYear:
          item.data.endYearADBC === 'BC'
            ? `-${item?.data?.eventEndDate?.split('-')[0]}`
            : item?.data?.eventEndDate?.split('-')[0],
        time: `${
          item.data.yearADBC === 'BC' ? `-${item.data.eventYear.split('-')[0]}` : item.data.eventYear.split('-')[0]
        }++${
          item.data.eventEndDate
            ? checkIfTimePeriodIsLessThan20(
                parseInt(item.data.eventYear.split('-')[0]),
                parseInt(item.data.eventEndDate.split('-')[0])
              )
              ? `${item.data.endYearADBC === 'BC' ? '-' : ''}${
                  parseInt(item.data.eventEndDate.split('-')[0]) + (item.type === 'events' ? 50 : 30)
                }`
              : `${item.data.eventEndDate.split('-')[0]}`
            : parseInt(item.data.eventYear.split('-')[0]) + 0
        }`,
      };
    })
    .sort(function (a, b) {
      return new Date(a.startTime) - new Date(b.startTime);
    });

  return (
    <TimeLineLayout activeItem={seasonTitle} isForTimelineGraph={true}>
      <TimeLineBar
        startTime={startDate}
        endTime={endDate}
        timeLineData={timeLineData}
        timelineJumpDate={timelineJumpDate}
        isTimeJumpBC={timelineJumpToDateAdBc === 'BC'}
      />
    </TimeLineLayout>
  );
};

export const query = graphql`
  query TimePeopleAndEvent($seasonBeginDate: Date, $seasonEndDate: Date, $seasonBeginDateAdBc: String) {
    allEventLists: allPrismicEvents(
      filter: {
        data: {
          event_year: { gte: $seasonBeginDate }
          event_year_ad_bc: { eq: $seasonBeginDateAdBc }
          display_on_main_timeline1: { eq: true }
        }
      }
      sort: { fields: data___event_year, order: ASC }
    ) {
      nodes {
        data {
          yearADBC: event_year_ad_bc
          endYearADBC: event_end_year_ad_bc
          eventEndDate: event_end_date
          eventYear: event_year
          title {
            text
          }
        }
        uid
        type
      }
    }
    allPeopleLists: allPrismicPeople(
      filter: {
        data: {
          birthdate: { gt: $seasonBeginDate }
          date_of_death: { lt: $seasonEndDate }
          ad_bc: { eq: $seasonBeginDateAdBc }
        }
      }
      sort: { fields: data___birthdate, order: ASC }
    ) {
      nodes {
        data {
          yearADBC: death_date_ad_bc
          endYearADBC: death_date_ad_bc
          eventYear: birthdate
          eventEndDate: date_of_death
          image {
            thumbnails
            url
          }
          title {
            text
          }
          timelineProfilePhoto: timeline_profile_photo {
            fixed(height: 60, width: 60) {
              src
            }
          }
        }
        uid
        type
      }
    }
  }
`;

export default TimeLineEventAndPeople;
