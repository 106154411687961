import React from 'react';
import ArrowLeft from './ArrayLeft.png';
import { Link } from 'gatsby';

const EventBar = ({
  item,
  isEventChecked,
  skipTime,
  onActiveBarClick,
  setSelectedBarTime,
  activeBar,
  setIsActiveBarEvent,
}) => {
  const { title, time, type, uid, startYear, endYear } = item;
  const defaultTimeSkip = 10;
  const changeInValue = skipTime - defaultTimeSkip > 0 ? skipTime - defaultTimeSkip - 2 : 0;
  const eventBarTitleFontSize = 14 - changeInValue;
  const eventLineHeight = 17 - changeInValue;
  const subTitleFontSize = 14 - changeInValue;
  const lineHeightSubtitle = 17 - changeInValue;

  const selectedBarTime = () => {
    onActiveBarClick(title);
    setSelectedBarTime(time);
    setIsActiveBarEvent(true);
  };
  return (
    <li
      className={`${activeBar === title ? 'activeBar' : ''}`}
      onMouseOver={selectedBarTime}
      onMouseOut={() => {
        selectedBarTime(undefined);
        onActiveBarClick(undefined);
      }}
      data-duration={time}
      data-type={type}
      style={{
        padding: isEventChecked ? 10 - (skipTime - 10) : 0,
        width: endYear ?? 'max-content',
        backgroundColor: activeBar === title ? '#fecb04' : '',
      }}
    >
      {isEventChecked && (
        <Link to={`/timeline-details/event/${uid}`}>
          <div style={{ display: 'flex', justifyContent: 'space-between', gap: '17px' }}>
            <div style={{ display: 'flex' }}>
              <div>
                <span
                  className="event-bar-title"
                  style={{ fontSize: `${eventBarTitleFontSize}px`, lineHeight: `${eventLineHeight}px` }}
                >
                  {title}
                </span>
                <span
                  className="bar-subtitle"
                  style={{
                    fontSize: `${subTitleFontSize}px`,
                    lineHeight: `${lineHeightSubtitle}px`,
                    marginLeft: '10px',
                  }}
                >
                  {startYear > 0 ? startYear : `${startYear?.replace('-', '')}BC`}
                  {endYear ? (endYear > 0 ? `-${endYear}` : `-${endYear?.replace('-', '')}BC`) : ''}
                </span>
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={ArrowLeft} alt="frame" />
            </div>
          </div>
        </Link>
      )}
    </li>
  );
};

export default EventBar;
