import React, { useRef } from 'react';
import Read from './Read.png';
import { Link } from 'gatsby';
import Frame from './Frame.png';
import Watch from './Watch.png';
import ArrowLeft from './ArrayLeft.png';

const PeopleBar = ({
  item,
  onActiveBarClick,
  activeBar,
  setSelectedBarTime,
  isPeopleChecked,
  skipTime,
  setIsActiveBarEvent,
}) => {
  const { image, title, time, type, uid, startYear, endYear } = item;

  const selectedBarTime = () => {
    onActiveBarClick(title);
    setSelectedBarTime(time);
    setIsActiveBarEvent(false);
  };

  const defaultTimeSkip = 10;
  const changeInValue = skipTime - defaultTimeSkip > 0 ? skipTime - defaultTimeSkip - 1 : 0;
  const titleFontSize = 16 - changeInValue;
  const subTitleFontSize = 14 - changeInValue;
  const lineHeight = 18 - changeInValue;
  const lineHeightSubtitle = 17 - changeInValue;
  const marginLeft = 10 - changeInValue;

  return (
    <li
      className={`${activeBar === title ? 'activeBar' : ''}`}
      data-duration={time}
      data-type={type}
      onMouseOver={selectedBarTime}
      style={{ backgroundColor: activeBar === title ? '#fecb04' : '' }}
      onMouseOut={() => {
        selectedBarTime(undefined);
        onActiveBarClick(undefined);
      }}
    >
      {isPeopleChecked && (
        <Link to={`/timeline-details/people/${uid}`}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', flex: 2 }}>
              <img src={image} alt="people" style={{ height: `${60 - (skipTime - 10)}px`, width: '60px' }} />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingLeft: '10px',
                  width: '100%',

                  justifyContent: 'center',
                }}
              >
                <span className="bar-title" style={{ fontSize: `${titleFontSize}px`, lineHeight: `${lineHeight}px` }}>
                  {title}
                </span>
                <span
                  className="bar-subtitle"
                  style={{ fontSize: `${subTitleFontSize}px`, lineHeight: `${lineHeightSubtitle}px` }}
                >
                  {startYear > 0 ? startYear : `${startYear.replace('-', '')}BC`}-
                  {endYear > 0 ? endYear : `${endYear.replace('-', '')}BC`}
                </span>
              </div>
            </div>
            <div
              to={`/timeline-details/people/${uid}`}
              style={{
                justifyContent: 'space-evenly',
                // width: 60 - changeInValue,
                margin: '0 5px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  justifyContent: 'space-around',
                  width: 120 - changeInValue,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div>
                  <img src={Read} alt="read" />
                </div>
                <div>
                  <img src={Watch} alt="watch" style={{ marginLeft: `${marginLeft}px` }} />
                </div>
                <div>
                  <img src={Frame} alt="frame" style={{ marginLeft: `${marginLeft}px` }} />
                </div>
                <div style={{ marginRight: 5 }}>
                  <img src={ArrowLeft} alt="frame" style={{ marginLeft: `${marginLeft}px` }} />
                </div>
              </div>
            </div>
          </div>
        </Link>
      )}
    </li>
  );
};

export default PeopleBar;
