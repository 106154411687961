import React, { useRef } from 'react';
import { Link } from 'gatsby';

import './time.css';
import Top from './top.png';
import Left from './Left.png';
import Down from './down.png';
import Plus from './plus.png';
import Back from './back.svg';
import Right from './right.png';
import Minus from './minus.png';
import EventBar from './EventBar';
import PeopleBar from './PeopleBar';
import Setting from './setting.png';
import CustomizedSwitches from '../switcher';
import Drawer from '@mui/material/Drawer';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import styled from '@emotion/styled';
import { peopleTimelineDataType, eventTimelineDataType } from './const';
import { css } from '@emotion/react';
import { AppContext } from '../../apollo/wrap-root-element';
const Time = ({ startTime, endTime, timeLineData, timelineJumpDate, isTimeJumpBC }) => {
  const {
    isEventChecked,
    setIsEventChecked,
    isPeopleChecked,
    setIsPeopleChecked,
    skipTime,
    setSkipTime,
    zoomValue,
    setZoomValue,
  } = React.useContext(AppContext);
  const jumpToYear = parseInt(timelineJumpDate?.split('-')[0]) * (isTimeJumpBC ? -1 : 1);
  const [activeYear, setActiveYear] = React.useState(null);

  const [activeBar, onActiveBarClick] = React.useState();
  const [selectedBarTime, setSelectedBarTime] = React.useState();

  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [isActiveBarEvent, setIsActiveBarEvent] = React.useState(false);

  const [lineBarStyle, setLineBarStyle] = React.useState();
  const [verticalLineHeight, setverticalLineHeight] = React.useState(0);
  const [isDown, setIsDown] = React.useState(false);
  const [prevScrollLeft, setPrevScrollLeft] = React.useState();
  const [startX, setStartX] = React.useState();
  const [startY, setStartY] = React.useState();
  const [prevScrollTop, setPrevScrollTop] = React.useState(0);

  let divRef = useRef();
  let timeSlotDivRef = useRef();

  const lengthOfTimeBar =
    ((endTime - startTime) / skipTime) * (startTime > endTime ? -1 : 1) + (startTime > endTime ? 2 * endTime : 0);

  const time = [parseInt(startTime) * (startTime > endTime ? -1 : 1)];
  let precedingTime = parseInt(startTime);

  React.useEffect(() => {
    const year = document.querySelectorAll('.chart-values li');
    const yearsArray = [...year];
    yearsArray.forEach((year) => {
      const interval = year.dataset.interval.split('++');
      const initialInterval = parseInt(interval[0]);
      const endInterval = parseInt(interval[1]);
      const updateJumpYear = activeYear ?? jumpToYear;
      if (initialInterval < updateJumpYear && updateJumpYear <= endInterval) {
        divRef.current.scrollLeft = year.offsetLeft + year.offsetWidth - divRef.current.offsetWidth / 2;
        timeSlotDivRef.current.scrollLeft = year.offsetLeft + year.offsetWidth - divRef.current.offsetWidth / 2;
        setPrevScrollLeft(year.offsetLeft + year.offsetWidth - divRef.current.offsetWidth / 2);
      }
    });
  }, [zoomValue, skipTime]);

  const getLeftPositionAndWidth = (timeSlot) => {
    const year = document.querySelectorAll('.chart-values li');

    const yearsArray = [...year];

    const duration = timeSlot?.split('++');
    const startDay = duration[0];
    const endDay = duration[1];
    let left = 0,
      width = 0;

    const startFilteredArray = yearsArray.filter((year) => {
      const interval = year.dataset.interval.split('++');
      const initialInterval = interval[0];
      const endInterval = interval[1];
      return parseInt(initialInterval) <= parseInt(startDay) && parseInt(endInterval) > parseInt(startDay);
    });

    const endFilteredArray = yearsArray.filter((year) => {
      const interval = year.dataset.interval.split('++');
      const initialInterval = interval[0];
      const endInterval = interval[1];

      return parseInt(initialInterval) <= parseInt(endDay) && parseInt(endInterval) > parseInt(endDay);
    });

    if (startFilteredArray.length && endFilteredArray.length) {
      if (parseInt(startFilteredArray[0].dataset.interval.split('++')[0]) === parseInt(startDay)) {
        left = startFilteredArray[0].offsetLeft + startFilteredArray[0].offsetWidth / 2;
      } else {
        const rem = parseInt(startDay) - parseInt(startFilteredArray[0].dataset.interval.split('++')[0]);
        const totalDivisionWidth = (rem * startFilteredArray[0].offsetWidth) / skipTime;
        left = startFilteredArray[0].offsetLeft + startFilteredArray[0].offsetWidth / 2 + totalDivisionWidth;
      }

      if (parseInt(endFilteredArray[0].dataset.interval.split('++')[1]) === parseInt(endDay)) {
        width = endFilteredArray[0].offsetLeft + endFilteredArray[0].offsetWidth - left;
      } else {
        const rem = parseInt(endDay) - parseInt(endFilteredArray[0].dataset.interval.split('++')[0]);
        const totalDivisionWidth = (rem * endFilteredArray[0].offsetWidth) / skipTime;
        width = endFilteredArray[0].offsetLeft + endFilteredArray[0].offsetWidth / 2 - left + totalDivisionWidth;
        if (startDay === endDay) {
          width = null;
        }
      }

      return { width, left };
    }
    return null;
  };

  for (let i = 0; i < lengthOfTimeBar; i++) {
    const multiplyBy = startTime > endTime ? -1 : 1;
    precedingTime = precedingTime + parseInt(skipTime) * multiplyBy;
    time.push(precedingTime * multiplyBy);
    if (activeYear) {
      if (precedingTime < activeYear && activeYear < precedingTime + parseInt(skipTime)) {
        time.push(activeYear);
      }
    }
  }

  const plotAllData = () => {
    const tasks = document.querySelectorAll('.chart-bars li');

    let maxTop = 0;
    let maxLeft = 0;
    let top = 0;

    const getTopPosition = (left, increaseTopBy) => {
      if (maxLeft > left) {
        top += increaseTopBy - (skipTime - 10);
      } else {
        top = 0;
      }
      return top;
    };
    tasks.forEach((el, index) => {
      const leftAndWidth = getLeftPositionAndWidth(el.dataset.duration);
      if (leftAndWidth) {
        const { left, width } = leftAndWidth;
        const type = el.dataset.type;
        const increaseTopBy =
          index > 0 && tasks[index - 1].dataset.type === eventTimelineDataType ? 52 - (skipTime - 10) : 70;
        getTopPosition(left, increaseTopBy);
        if (left + width > maxLeft) {
          maxLeft = left + width;
        }
        if (top > maxTop) {
          maxTop = top;
        }
        el.style.left = `${left}px`;
        el.style.top = `${top}px`;
        el.style.position = `absolute`;
        el.style.width = type === peopleTimelineDataType ? `${width}px` : `${width}px`;
      }
    });
    setverticalLineHeight(maxTop);
  };

  React.useEffect(() => {
    plotAllData();
  }, [skipTime, zoomValue, isEventChecked, isPeopleChecked]);

  React.useEffect(() => {
    if (selectedBarTime) {
      const leftAndWidthValue = getLeftPositionAndWidth(selectedBarTime);

      const width = leftAndWidthValue?.width
        ? leftAndWidthValue?.width
        : document.querySelector('.activeBar')?.getBoundingClientRect().width;

      setLineBarStyle({ ...leftAndWidthValue, width });
    }
  }, [selectedBarTime, activeBar, skipTime]);

  const slide = (shift) => {
    divRef.current.scrollLeft += shift;

    timeSlotDivRef.current.scrollLeft += shift;
  };

  const scrollTopDown = (shift) => {
    divRef.current.scrollTop += shift;
    setPrevScrollTop(divRef.current.scrollTop);
  };

  const mouseMove = (e) => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX;
    const walk = (x - startX) * 2;
    const dy = e.clientY - startY;
    divRef.current.scrollLeft = prevScrollLeft - walk;
    divRef.current.scrollTop = prevScrollTop - dy;
    setPrevScrollLeft(prevScrollLeft - walk);
    setPrevScrollTop(prevScrollTop - dy);
    setStartX(e.pageX);
  };

  const isInBetweenTimeSlot = (selectedBarTime, time) => {
    if (selectedBarTime) {
      const selectedTime = selectedBarTime?.split('++');

      return parseInt(selectedTime[0]) <= parseInt(time) && parseInt(time) < parseInt(selectedTime[1]);
    }
    return false;
  };

  const onScroll = (e) => {
    timeSlotDivRef.current.scrollLeft = e.target.scrollLeft;
  };

  const onTimeSlotScroll = (e) => {
    divRef.current.scrollLeft = e.target.scrollLeft;
  };

  const LineSlotDynamicStyle = (props) =>
    css`
      ::before {
        content: '';
        position: absolute;
        right: 50%;
        height: ${typeof window !== 'undefined' && window.innerHeight < props.height
          ? `${props.height}px`
          : typeof window !== 'undefined' && window.innerHeight - props.height < 200
          ? typeof window !== 'undefined' && `${window.innerHeight}px`
          : '88vh'};
        border-right: 1px solid ${props.isActiveYear ? '#fecb04' : '#656565'};
        display: block;
      }
    `;
  const LineSlot = styled('li')`
    ${LineSlotDynamicStyle}
  `;

  return (
    <>
      <Drawer anchor={'bottom'} open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        <div style={{ backgroundColor: '#0A0A0A', padding: '21px 24px 75px 24px ' }}>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CloseOutlinedIcon style={{ cursor: 'pointer' }} onClick={() => setIsDrawerOpen(false)} />
          </div>
          <div
            style={{
              display: 'flex',
              backgroundColor: '#171717',
              marginRight: '10px',
              justifyContent: 'space-between',
              padding: '0 10px',
              marginTop: 19,
            }}
          >
            <span style={{ color: '#656565', margin: 'auto 0 ' }}>PEOPLE</span>
            <CustomizedSwitches checked={isPeopleChecked} onChange={() => setIsPeopleChecked(!isPeopleChecked)} />
          </div>
          <div
            style={{
              display: 'flex',
              backgroundColor: '#171717',
              marginRight: '10px',
              justifyContent: 'space-between',
              padding: '0 10px',
              marginTop: 10,
            }}
          >
            <span style={{ color: '#656565', margin: 'auto 0' }}>EVENT</span>
            <CustomizedSwitches checked={isEventChecked} onChange={() => setIsEventChecked(!isEventChecked)} />
          </div>
        </div>
      </Drawer>
      <div className="container">
        <div className="timeline-button-actions">
          <div className="button-actions mobile-settings-bar">
            <button className="top-down-arrow" onClick={() => setIsDrawerOpen(true)}>
              <img src={Setting} alt="right" />
            </button>
          </div>
        </div>
        <div className="main-container">
          <button className="side-arrow-button arrow-right" onClick={() => slide(100)}>
            <img src={Left} alt="right" />
          </button>
          <button className="side-arrow-button arrow-left" onClick={() => slide(-100)}>
            <img src={Right} alt="right" />
          </button>
          <div
            className="chart-wrapper"
            ref={divRef}
            onScroll={onScroll}
            onMouseMove={mouseMove}
            onMouseDown={(e) => {
              setStartY(e.pageY);
              setStartX(e.pageX);
              setIsDown(true);
              setPrevScrollLeft(divRef.current.scrollLeft);
            }}
            style={{
              cursor: isDown ? 'grab' : '',
            }}
            onMouseLeave={() => setIsDown(false)}
            onMouseUp={() => setIsDown(false)}
          >
            <ul className="line-container" style={{ height: '100%' }}>
              {lineBarStyle && activeBar && (
                <>
                  <div
                    style={{
                      clipPath: 'polygon(50% 0%, 47% 0, 48% 100%)',
                      height: `calc(100% - ${
                        document.querySelector('.activeBar')?.getBoundingClientRect().top - 50
                      }px )`,
                      position: 'absolute',
                      width: '150px',
                      zIndex: 0,
                      backgroundColor: '#fecb04',
                      left: lineBarStyle.left - 70,
                      top:
                        document.querySelector('.activeBar')?.getBoundingClientRect().top -
                        (isActiveBarEvent ? 40 : 20),
                    }}
                  >
                    <div></div>
                  </div>

                  <div
                    style={{
                      clipPath: 'polygon(50% 0%, 47% 0, 48% 100%)',
                      height: `calc(100% - ${
                        document.querySelector('.activeBar')?.getBoundingClientRect().top - 50
                      }px)`,
                      position: 'absolute',
                      width: '150px',
                      zIndex: 0,
                      backgroundColor: '#fecb04',
                      left: lineBarStyle.left + lineBarStyle.width - 75,
                      top:
                        document.querySelector('.activeBar')?.getBoundingClientRect().top -
                        (isActiveBarEvent ? 40 : 20),
                    }}
                  ></div>
                </>
              )}
              {time.map((item) => {
                return (
                  <>
                    <LineSlot
                      isActiveYear={item === activeYear}
                      height={`${verticalLineHeight + 70}`}
                      onClick={() => setActiveYear(item)}
                    ></LineSlot>
                  </>
                );
              })}
            </ul>

            <ul className="chart-bars">
              {timeLineData?.map((item) =>
                item.type === peopleTimelineDataType ? (
                  <PeopleBar
                    item={item}
                    onActiveBarClick={onActiveBarClick}
                    activeBar={activeBar}
                    setSelectedBarTime={(time) => setSelectedBarTime(time)}
                    isPeopleChecked={isPeopleChecked}
                    skipTime={skipTime}
                    setIsActiveBarEvent={setIsActiveBarEvent}
                  />
                ) : (
                  <EventBar
                    item={item}
                    isEventChecked={isEventChecked}
                    skipTime={skipTime}
                    onActiveBarClick={onActiveBarClick}
                    activeBar={activeBar}
                    setSelectedBarTime={(time) => setSelectedBarTime(time)}
                    setIsActiveBarEvent={setIsActiveBarEvent}
                  />
                )
              )}
            </ul>
          </div>

          <ul className="chart-values" ref={timeSlotDivRef} onScroll={onTimeSlotScroll}>
            {activeBar && (
              <>
                <div
                  style={{
                    position: 'absolute',
                    ...lineBarStyle,
                    height: 300,
                    zIndex: 200,
                    backgroundColor: 'rgb(101, 101, 101,0.34)',
                  }}
                  className="active-line-bar"
                ></div>
              </>
            )}
            {time.map((item) => {
              return (
                <li
                  id={`${
                    isInBetweenTimeSlot(`${item}-${item + parseInt(skipTime)}`, jumpToYear) ? 'jumpToYear' : item
                  }`}
                  data-interval={`${item}++${item + parseInt(skipTime)}`}
                  style={{
                    backgroundColor: activeYear === item ? ' #fecb04' : '',
                    color: activeYear === item ? '#0A0A0A' : '',
                    zIndex: activeYear === item ? 300 : isInBetweenTimeSlot(selectedBarTime, item) ? 200 : 0,
                  }}
                  className={`${activeYear === item ? 'scale' : ''}`}
                  onClick={() => setActiveYear(item)}
                >
                  {item}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Time;
